const DatePicker = {
  data() {
      return {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
             monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
             weekdaysMin: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
          },
        },
      }
    },
};

export default DatePicker;
